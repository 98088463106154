import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [];

  connect(){
    var map = L.map('courses-map');
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

    var markers = [];

    var raw_markers = JSON.parse(this.data.get("markers"))
    raw_markers.forEach((marker, index) => {
      var course = L.marker([marker[0], marker[1]], {id: marker[3]}).addTo(map).on('click', function(event){
        $.get( "/rankings/course/" + event.target.options.id, function( data ) {
          $('#course-detail').html(data);
        });
      }).bindPopup(marker[2]);
      markers.push(course);
    })

    var group = new L.featureGroup(markers);
      map.setView([46.71, 2.17], 6);
  }

}
