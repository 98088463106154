require("@rails/ujs").start()
import Turbolinks from 'turbolinks';
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import slick from 'slick-carousel';
import customSelect from 'jquery-custom-select';
import { Cleave } from "cleave.js";

Turbolinks.start();

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(document).on('turbolinks:load', function () {
  var customSelectOptions = {
    includeValue: true
  };
  if ($('.custom-select').data('placeholder')) {
    var placeholderOption = { placeholder: $('.custom-select').data('placeholder') }
    customSelectOptions = { ...customSelectOptions, ...placeholderOption }
  }
  // Custom/Skined form select
  $('.custom-select').customSelect(customSelectOptions);

  // Home raffle slider
  $('.home__cadeaux .slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true
  });

  // Home partners slider
  $('.home__partners .slider').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: false,
    prevArrow: $('.home__partners .prev'),
    nextArrow: $('.home__partners .next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });
});
