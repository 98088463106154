import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [];


  connect(){
    new Cleave(this.element, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y']
  })
  }

}
