import { Controller } from "stimulus";
import Cookies from "js-cookie";
export default class extends Controller {
  static targets = ["donation", "total", "notice"];

  addDonationToAnualFee() {

    var in30Minutes = 1/48;
    Cookies.set('donationAmount', this.donationTarget.value, {
      expires: in30Minutes
    });

    if(parseInt(this.donationTarget.value) == 1){
      this.noticeTarget.style.visibility = "visible";
    }
    else {
      this.noticeTarget.style.visibility = "hidden";
    }
    if(parseInt(this.donationTarget.value) > 0){
      this.totalTarget.textContent = parseInt(this.data.get("baseAmount")) + parseInt(this.donationTarget.value);
    }
    else {
      this.totalTarget.textContent = this.data.get("baseAmount");
    }
  }

  populateDonationInput(e) {
    let value = e.target.dataset.value;
    this.donationTarget.value = value;
    this.addDonationToAnualFee();
  }

  connect(){
    if(Cookies.get('donationAmount') && parseInt(Cookies.get('donationAmount')) > 0){
      this.donationTarget.value = Cookies.get('donationAmount');
    }
    this.addDonationToAnualFee();
  }

}
