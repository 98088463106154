import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["winners"];

  link(e) {
    e.preventDefault()

    if (this.winnersTarget.style.display == 'block') {
      this.winnersTarget.style.display = 'none'
    } else {
      this.winnersTarget.style.display = 'block'

    }
  }
}