import { Controller } from "stimulus";
import autosize from "autosize";

export default class extends Controller {
  static targets = [];

  showTextarea() {
    $("#form").show();
    var toggle = document.querySelectorAll('[data-toggle="autosize"]');
    if (typeof autosize !== "undefined" && toggle) {
      [].forEach.call(toggle, function(el) {
        autosize(el);
      });
    }
  }

  createCodes() {
    var code_count = prompt("Combien de code ?", "20");
    var params = {
      code_count: code_count,
      authenticity_token: $('meta[name="csrf-token"]')[0].content
    };
    if (code_count != null) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = this.data.get("url");

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];

          form.appendChild(hiddenField);
        }
      }

      document.body.appendChild(form);
      form.submit();
    }
  }
}
