import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

const Trix = require('trix');

export default class extends Controller {
  connect() {
    const toolbar = this.element.toolbarElement;
    const embedButton = `
      <button type="button" class="trix-button trix-button--icon trix-button--icon-video"
        data-trix-attribute="video" title="Embed Video" tabindex="-1">Video</button>`;
    const videoDialog = `
      <div class="trix-dialog trix-dialog--video" data-trix-dialog="video" data-trix-dialog-attribute="video">
         <div class="trix-dialog__link-fields">
          <input type="url" name="video" class="trix-input trix-input--dialog" 
            placeholder="Enter a Youtube URL…" aria-label="URL" required="" data-trix-input="" disabled="disabled">
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="Insert" 
              data-trix-method="setAttribute" data-trix-dialog-submit--video>
            <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>`;

    toolbar.querySelector('.trix-button-group--block-tools').insertAdjacentHTML('beforeend', embedButton);
    toolbar.querySelector('[data-trix-dialogs]').insertAdjacentHTML('beforeend', videoDialog);
    toolbar.querySelector('[data-trix-dialog-submit--video]').addEventListener('click', () => {
      const videoElement = document.querySelector('[data-trix-dialog=video] input[name=video]');
      if (videoElement.value) {
        const youtubeId = this.getYoutubeId(videoElement.value);
        Rails.ajax({
          url: `/youtube/${encodeURIComponent(youtubeId)}`,
          type: 'get',
          success: (data) => {
            const attachmentVideo = new Trix.Attachment(data)
            this.element.editor.insertAttachment(attachmentVideo);
          }
        })
      }
    });
  }

  getYoutubeId(url) {
    if (url == undefined) {
      return null
    } else {
      var e = url.match(
        /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
      )
      return !(!e || 11 != e[1].length) && e[1]
    }
  }
}