import { Controller } from "stimulus";
import "awesomplete";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    var titles = JSON.parse(
      this.element.getAttribute("data-operations-titles")
    );

    new Awesomplete(this.inputTarget, {
      list: titles
    });
  }
}
